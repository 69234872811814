<template>
    <div class="fullscreen d-flex flex-column">
        <div class="wheel-header">
            <v-img contain style="height: min(10vh, 80vw)" src="https://app.wellcomefit.ch/img/wcf-logo-sb.png"></v-img>
        </div>
        <div class="wheel-container">
            <svg :width="size" :height="size" :viewBox="`0 0 ${size} ${size}`" class="wheel" ref="wheel">
                <g v-for="(prize, index) in prizes" :key="index">
                    <path :d="drawSegment(index)" :fill="getSegmentColor(index)" stroke="#fff" stroke-width="1"></path>

                    <text
                        :x="getTextX(index)"
                        :y="getTextY(index)"
                        :transform="getTextRotation(index)"
                        text-anchor="middle"
                        fill="#000"
                        :style="{
                            fontSize: `${size / 30}px`,
                            'text-transform': 'uppercase',
                            'font-weight': 'bold',
                            'letter-spacing': '1px',
                        }"
                    >
                        {{ prize.name }}
                    </text>
                </g>
            </svg>

            <div class="spin-button-group" @click="spinWheel" :disabled="isSpinning || showDialog">
                <div class="spin-button-arrow"></div>
                <div class="spin-button-circle">
                    <div class="spin-button">SPIN</div>
                </div>
            </div>
        </div>
        <div v-if="!isSpinning && !showDialog" class="wheel-text">
            <span>PRESS THE BUZZER!</span>
        </div>
        <div v-if="isSpinning" class="wheel-footer">
            <span>
                Dein nächster Schritt
                <br />
                zu mehr Fitness!
            </span>
        </div>
        <div class="wheel-qr">
            <v-img contain style="height: min(12vh, 80vw)" src="https://app.wellcomefit.ch/img/qrcode_hp.png"></v-img>
        </div>
        <!-- Gewinn-Dialog -->
        <v-dialog
            max-width="80vw"
            max-height="80vh"
            v-model="showDialog"
            fullscreen
            persistent
            no-click-animation
            @keydown.native="onDialogKeyDown"
            class="win-dialog-parent"
        >
            <v-card no-click-animation :ripple="false" class="win-dialog" ref="winDialog" tabindex="0">
                <div class="prize-header-section">
                    <lottie-player mode="normal" loop autoplay src="/img/lottiefiles/prizeCrown.json"></lottie-player>
                    <div class="prize-header-section-text">Du hast gewonnen!</div>
                </div>
                <div class="prize-section">
                    <div class="prize-subheader">Dein Preis</div>
                    <div class="prize-header">{{ selectedPrize ? selectedPrize.name : '11 Tage Fitness' }}</div>
                    <div class="prize-seperator"></div>
                    <div class="prize-footer">Hol dir deinen Gewinn</div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { gsap } from 'gsap'
import confetti from 'canvas-confetti'

export default {
    data() {
        return {
            size: this.computeSize(), // Dynamische Größe des Rads
            prizes: [
                { name: '11-Tage', probability: 0.91 },
                { name: 'Aktivierungs-Tarif*', probability: 0.03 },
                { name: '11-Tage', probability: 0 },
                { name: '1 Jahresabo', probability: 0 },
                { name: '11-Tage', probability: 0 },
                { name: '+2 Monate*', probability: 0.03 },
                { name: '11-Tage', probability: 0 },
                { name: 'Gratis-Eintritt', probability: 0 },
                { name: '11-Tage', probability: 0 },
                { name: '+1 Monat*', probability: 0.03 },
                { name: '11-Tage', probability: 0 },
                { name: 'Spin Again', probability: 0 },
            ],
            selectedPrize: null,
            isSpinning: false,
            textRadius: null,
            winSound: null,
            segmentSound: null,
            previousSegment: null, // Verfolgt das zuvor erreichte Segment
            showDialog: false,
            idleTween: null, // Für das Idle-Spinning
            dialogCanBeClosed: false, // Dialog kann erst nach 5 Sekunden geschlossen werden
            autoCloseDialogTimeout: null, // Timeout für automatisches Schließen des Dialogs
        }
    },
    mounted() {
        // Radius für die Textposition berechnen
        this.textRadius = this.size / 2 - 30 // Abstand vom Mittelpunkt

        // Event Listener für Fenstergröße hinzufügen
        window.addEventListener('resize', this.onResize)

        // Event Listener für Tastendruck hinzufügen
        window.addEventListener('keydown', this.onKeyDown)

        this.winSound = new Audio('https://app.wellcomefit.ch/img/einmalig/winSound.mp3')
        this.segmentSound = new Audio('https://app.wellcomefit.ch/img/einmalig/segmentSound.mp3')

        this.startIdleSpin()
    },
    beforeDestroy() {
        // Event Listener entfernen, wenn die Komponente zerstört wird
        window.removeEventListener('resize', this.onResize)

        // Event Listener für Tastendruck entfernen
        window.removeEventListener('keydown', this.onKeyDown)

        this.stopIdleSpin()
    },
    methods: {
        showWinDialog() {
            this.showDialog = true
            this.dialogCanBeClosed = false

            // Erlaubt das Schließen nach 5 Sekunden
            setTimeout(() => {
                this.dialogCanBeClosed = true
            }, 5000) // 5 Sekunden

            // Automatisches Schließen nach 2 Minuten
            this.autoCloseDialogTimeout = setTimeout(() => {
                if (this.showDialog) {
                    this.closeDialog()
                }
            }, 120000) // 2 Minuten
        },

        // Methode zum Schließen des Dialogs und erneuten Starten des Rads
        closeDialog() {
            this.showDialog = false

            // Automatisches Schließen stoppen, falls vorhanden
            if (this.autoCloseDialogTimeout) {
                clearTimeout(this.autoCloseDialogTimeout)
                this.autoCloseDialogTimeout = null
            }

            // Starte das Idle-Spinning erneut
            this.startIdleSpin()

            // Setze das Rad zurück
            this.resetWheel()
        },

        // Methode zum Erfassen von Tastendrücken, wenn der Dialog geöffnet ist
        onDialogKeyDown(event) {
            if ((event.key === 'b' || event.key === 'B') && this.dialogCanBeClosed) {
                this.closeDialog()
            }
        },
        computeSize() {
            // Berechne die Größe als Minimum von 80% der Viewport-Breite und -Höhe
            const width = window.innerWidth * 0.8
            const height = window.innerHeight * 0.5
            return Math.min(width, height)
        },
        onResize() {
            // Aktualisiere die Größe und abhängige Werte bei Größenänderung des Fensters
            this.size = this.computeSize()
            this.textRadius = this.size / 2 - 30
            // Optional: Aktualisiere das Rad neu zeichnen oder andere Abhängigkeiten
        },

        onKeyDown(event) {
            if (event.key === 'b' || event.key === 'B') {
                if (this.showDialog) {
                    if (this.dialogCanBeClosed) {
                        this.closeDialog()
                    }
                } else if (!this.isSpinning) {
                    this.spinWheel()
                }
            }
        },
        drawSegment(index) {
            const numSegments = this.prizes.length
            const angle = (2 * Math.PI) / numSegments
            const startAngle = index * angle
            const endAngle = startAngle + angle
            const radius = this.size / 2

            const x1 = radius + radius * Math.cos(startAngle)
            const y1 = radius + radius * Math.sin(startAngle)
            const x2 = radius + radius * Math.cos(endAngle)
            const y2 = radius + radius * Math.sin(endAngle)

            return `
                  M ${radius},${radius}
                  L ${x1},${y1}
                  A ${radius},${radius} 0 0,1 ${x2},${y2}
                  Z
                   `
        },
        getSegmentColor(index) {
            return index % 2 ? '#ffffff' : '#dedede'
        },
        getTextX(index) {
            const radius = this.size / 2
            const angle = ((2 * Math.PI) / this.prizes.length) * (index + 0.6)
            const textRadius = radius * 0.6 // Anpassung des Abstands vom Mittelpunkt
            return radius + textRadius * Math.cos(angle)
        },
        getTextY(index) {
            const radius = this.size / 2
            const angle = ((2 * Math.PI) / this.prizes.length) * (index + 0.6)
            const textRadius = radius * 0.6 // Anpassung des Abstands vom Mittelpunkt
            return radius + textRadius * Math.sin(angle)
        },
        getTextRotation(index) {
            const numSegments = this.prizes.length
            const angle = (360 / numSegments) * index + 180 / numSegments
            const x = this.getTextX(index)
            const y = this.getTextY(index)
            return `rotate(${angle} ${x} ${y})`
        },
        selectPrize() {
            const rand = Math.random()
            let cumulativeProbability = 0
            for (let prize of this.prizes) {
                cumulativeProbability += prize.probability
                if (rand <= cumulativeProbability) {
                    this.selectedPrize = prize
                    break
                }
            }
        },
        resetWheel() {
            gsap.set(this.$refs.wheel, {
                rotation: 0,
            })
            this.currentRotation = 0
            this.previousSegment = null // Vorheriges Segment zurücksetzen
        },
        playSegmentSound(newSegment) {
            // Wenn das neue Segment vom vorherigen abweicht, spiele den Sound ab
            if (newSegment !== this.previousSegment) {
                this.segmentSound.currentTime = 0 // Startet den Sound von vorne
                this.segmentSound.play()
                this.previousSegment = newSegment // Aktualisiert das vorherige Segment
            }
        },
        // Startet das langsame Drehen im Idle-Modus
        startIdleSpin() {
            // Beginnt ein langsames, kontinuierliches Drehen des Rads
            this.idleTween = gsap.to(this.$refs.wheel, {
                rotation: '+=360',
                duration: 60, // Dauer für eine volle Umdrehung (in Sekunden)
                repeat: -1, // Unendliches Wiederholen
                ease: 'linear',
            })
        },

        // Stoppt das Idle-Spinning
        stopIdleSpin() {
            if (this.idleTween) {
                this.idleTween.kill()
                this.idleTween = null
            }
        },
        spinWheel() {
            if (this.isSpinning || this.showDialog) return

            this.stopIdleSpin() // Stoppt das Idle-Spinning, bevor der Spin beginnt

            this.resetWheel()
            this.isSpinning = true
            this.selectPrize()

            const minSpins = 15 // Mindestanzahl an Umdrehungen
            const numSegments = this.prizes.length
            const segmentAngle = 360 / numSegments
            const prizeIndex = this.prizes.indexOf(this.selectedPrize)
            const segmentCenterAngle = segmentAngle * prizeIndex + segmentAngle / 2
            const rotationNeeded = minSpins * 360 + (270 - segmentCenterAngle)

            gsap.to(this.$refs.wheel, {
                rotation: rotationNeeded,
                duration: 15,
                ease: 'power4.out',
                onUpdate: () => {
                    const currentRotation = (gsap.getProperty(this.$refs.wheel, 'rotation') - 18) % 360
                    const currentSegment = Math.floor(currentRotation / segmentAngle)
                    this.playSegmentSound(currentSegment)
                },
                onComplete: () => {
                    this.winSound.play()
                    this.isSpinning = false
                    this.currentRotation = rotationNeeded % 360

                    // Zeige den Gewinn-Dialog an
                    this.showWinDialog()

                    // Starte die Konfetti-Explosion
                    this.launchConfetti()
                },
            })
        },
        launchConfetti() {
            const duration = 5 * 1000 // Konfetti läuft 3 Sekunden lang
            const animationEnd = Date.now() + duration

            function randomInRange(min, max) {
                return Math.random() * (max - min) + min
            }

            ;(function frame() {
                const timeLeft = animationEnd - Date.now()

                if (timeLeft <= 0) return // Stoppe die Animation, wenn die Zeit abgelaufen ist

                const particleCount = Math.floor(10 * (timeLeft / duration)) // Dynamisch weniger Partikel mit der Zeit

                // Explosion mit einem Spread (Verteilung der Partikel) und größerem Bereich
                confetti({
                    particleCount: particleCount,
                    startVelocity: 25, // Moderate Startgeschwindigkeit
                    spread: 90, // Breiterer Spread für schönere visuelle Wirkung
                    origin: {
                        x: randomInRange(0.2, 0.8), // Zufällige Positionen im horizontalen Bereich
                        y: randomInRange(0.2, 0.4), // Position über dem Bildschirm für realistischere Gravitation
                    },
                    gravity: randomInRange(0.5, 0.7), // Realistische Schwerkraft
                    scalar: randomInRange(0.8, 1.5), // Kleinere bis mittlere Partikel
                    zIndex: 9999,
                })

                // Weniger häufiges requestAnimationFrame für bessere Performance
                setTimeout(() => {
                    requestAnimationFrame(frame)
                }, 20) // Alle 20ms, um die CPU-Last zu minimieren
            })()
        },
    },
}
</script>
<style lang="scss" scoped>
/* Vollbild-Container */
.fullscreen {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column; /* Flex column for default layout */
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    background-color: #00963f;
}

/* Wheel Header */
.wheel-header {
    position: absolute;
    top: 5%;
    width: 80vmin;
    max-width: 80%;
    height: auto;
}

/* Rad-Container */
.wheel-container {
    position: relative;
    width: 80vmin;
    height: 80vmin;
    max-width: 80%;
    max-height: 80%;
    margin-top: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -5vh;
}

/* Wheel Text */
.wheel-text {
    position: absolute;
    bottom: 18%;
    font-size: 5vmin;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.5vmin;
    color: white;
    animation: float 2s ease-in-out infinite;
    text-align: center;
    width: 100%;
}

@keyframes float {
    0% {
        font-size: 5vmin;
    }
    50% {
        font-size: 5.5vmin;
    }
    100% {
        font-size: 5vmin;
    }
}

/* Wheel Footer */
.wheel-footer {
    position: absolute;
    bottom: 18%;
    font-size: 3vmin;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.3vmin;
    max-width: 80%;
    text-align: center;
}

/* QR Code */
.wheel-qr {
    position: absolute;
    bottom: 4%;
    width: 20vmin;
    max-width: 20%;
    height: auto;
}

/* The Wheel */
.wheel {
    transform-origin: center center;
    margin: 0 auto;
    display: block;
    border-radius: 50%;
    padding: 1vmin;
    background-color: white;
    box-shadow: 0 0 0.5vmin rgba(0, 0, 0, 0.2), 0 0 1vmin rgba(0, 0, 0, 0.14), 0 0 1.5vmin rgba(0, 0, 0, 0.12);
}

/* Spin Button Group */
.spin-button-group {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    filter: drop-shadow(0 0.3vmin 0.3vmin rgba(0, 0, 0, 0.2));
}

/* Arrow */
.spin-button-arrow {
    width: 0;
    height: 0;
    border-left: 5vmin solid transparent;
    border-right: 5vmin solid transparent;
    border-bottom: 5vmin solid white;
    margin-bottom: -2.3vmin;
}

/* Spin Button Circle */
.spin-button-circle {
    position: relative;
    width: 13vmin;
    height: 13vmin;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Spin Button */
.spin-button {
    width: 10vmin;
    height: 10vmin;
    background-color: #00963f;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5vmin;
    cursor: pointer;
    user-select: none;
    letter-spacing: 0.2vmin;
    font-weight: bold;
}

/* Disabled Button */
.spin-button[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
}

/* Win Dialog */
.win-dialog {
    text-align: center;
    background-color: #313131 !important;
    color: #fff;
    padding: 5vh 5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Prize Header Section */
.prize-header-section {
    &-text {
        font-size: 6vmin;
        font-weight: bold;
        animation: float 2s ease-in-out infinite;
        letter-spacing: 0.5vmin;
        line-height: 8vmin;
        margin-bottom: 3vh;
    }
}

/* Prize Section */
.prize-section {
    background: linear-gradient(90deg, #ffd200 0%, #ffaa00 100%);
    border-radius: 5vmin !important;
    padding: 3vmin 5vmin;
    margin-bottom: 2vmin;
    height: auto;
    width: 80%;
    position: relative;
    margin-top: 5vh;
}

/* Prize Section Decorations */
.prize-section::before,
.prize-section::after {
    content: '';
    position: absolute;
    bottom: calc(50% - 4vmin);
    height: 8vmin;
    width: 8vmin;
    border-radius: 50%;
    background-color: #313131;
}

.prize-section::before {
    left: -4vmin;
}

.prize-section::after {
    right: -4vmin;
}

/* Prize Subheader */
.prize-subheader {
    font-size: 3vmin;
    font-weight: bold;
    letter-spacing: 0.3vmin;
    margin-bottom: 2vmin;
}

/* Prize Header */
.prize-header {
    font-size: 6vmin;
    font-weight: bold;
    letter-spacing: 0.3vmin;
    margin-bottom: 2vmin;
}

/* Prize Separator */
.prize-seperator {
    width: 100%;
    height: 0.5vmin;
    background: repeating-linear-gradient(
        to right,
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0.8) 1vmin,
        transparent 1vmin,
        transparent 2vmin
    );
    margin-bottom: 2vmin;
}

/* Prize Footer */
.prize-footer {
    font-size: 3vmin;
    font-weight: bold;
    letter-spacing: 0.3vmin;
    background-color: white;
    color: #1b1b1b;
    border-radius: 3vmin;
    padding: 2vmin;
    margin-top: 2vmin;
}

/* Crown Icon */
.prize-header-section lottie-player {
    width: max(40vw, 5vh);
}

.prize-header-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Media Queries for Landscape Orientation */
@media (orientation: landscape) {
    .fullscreen {
        flex-direction: row; /* Change to row layout */
    }

    .wheel-header {
        position: absolute;
        top: 5%;
        left: 5%;
        width: 15vmin;
    }

    .wheel-container {
        width: 50vmin;
        height: 50vmin;
        margin-top: 0;
    }

    .wheel-text,
    .wheel-footer {
        bottom: 7%;
    }

    .wheel-qr {
        bottom: 5%;
        right: 5%;
        width: 10vmin;
    }

    .win-dialog {
        padding: 5vh 10vw;
    }

    .prize-header-section lottie-player {
        width: 30vmin;
    }

    .prize-header-section-text {
        font-size: 5vmin;
        line-height: 6vmin;
    }

    .prize-subheader,
    .prize-header,
    .prize-footer {
        font-size: 2.5vmin;
    }

    .prize-section {
        width: 40%;
    }
}

/* Adjustments for Small Screens */
@media (max-width: 600px) {
    .wheel-text {
        font-size: 7vmin;
    }

    .wheel-footer {
        font-size: 5vmin;
    }

    .spin-button {
        width: 12vmin;
        height: 12vmin;
        font-size: 4vmin;
    }

    .spin-button-circle {
        width: 15vmin;
        height: 15vmin;
    }

    .spin-button-arrow {
        border-left: 6vmin solid transparent;
        border-right: 6vmin solid transparent;
        border-bottom: 6vmin solid white;
        margin-bottom: -3vmin;
    }

    .prize-header-section-text {
        font-size: 6vmin;
    }

    .prize-subheader,
    .prize-footer {
        font-size: 4vmin;
    }

    .prize-header {
        font-size: 8vmin;
    }
}
</style>
