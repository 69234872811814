var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fullscreen d-flex flex-column"},[_c('div',{staticClass:"wheel-header"},[_c('v-img',{staticStyle:{"height":"min(10vh, 80vw)"},attrs:{"contain":"","src":"https://app.wellcomefit.ch/img/wcf-logo-sb.png"}})],1),_c('div',{staticClass:"wheel-container"},[_c('svg',{ref:"wheel",staticClass:"wheel",attrs:{"width":_vm.size,"height":_vm.size,"viewBox":`0 0 ${_vm.size} ${_vm.size}`}},_vm._l((_vm.prizes),function(prize,index){return _c('g',{key:index},[_c('path',{attrs:{"d":_vm.drawSegment(index),"fill":_vm.getSegmentColor(index),"stroke":"#fff","stroke-width":"1"}}),_c('text',{style:({
                        fontSize: `${_vm.size / 30}px`,
                        'text-transform': 'uppercase',
                        'font-weight': 'bold',
                        'letter-spacing': '1px',
                    }),attrs:{"x":_vm.getTextX(index),"y":_vm.getTextY(index),"transform":_vm.getTextRotation(index),"text-anchor":"middle","fill":"#000"}},[_vm._v(" "+_vm._s(prize.name)+" ")])])}),0),_c('div',{staticClass:"spin-button-group",attrs:{"disabled":_vm.isSpinning || _vm.showDialog},on:{"click":_vm.spinWheel}},[_c('div',{staticClass:"spin-button-arrow"}),_vm._m(0)])]),(!_vm.isSpinning && !_vm.showDialog)?_c('div',{staticClass:"wheel-text"},[_c('span',[_vm._v("PRESS THE BUZZER!")])]):_vm._e(),(_vm.isSpinning)?_c('div',{staticClass:"wheel-footer"},[_vm._m(1)]):_vm._e(),_c('div',{staticClass:"wheel-qr"},[_c('v-img',{staticStyle:{"height":"min(12vh, 80vw)"},attrs:{"contain":"","src":"https://app.wellcomefit.ch/img/qrcode_hp.png"}})],1),_c('v-dialog',{staticClass:"win-dialog-parent",attrs:{"max-width":"80vw","max-height":"80vh","fullscreen":"","persistent":"","no-click-animation":""},nativeOn:{"keydown":function($event){return _vm.onDialogKeyDown.apply(null, arguments)}},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{ref:"winDialog",staticClass:"win-dialog",attrs:{"no-click-animation":"","ripple":false,"tabindex":"0"}},[_c('div',{staticClass:"prize-header-section"},[_c('lottie-player',{attrs:{"mode":"normal","loop":"","autoplay":"","src":"/img/lottiefiles/prizeCrown.json"}}),_c('div',{staticClass:"prize-header-section-text"},[_vm._v("Du hast gewonnen!")])],1),_c('div',{staticClass:"prize-section"},[_c('div',{staticClass:"prize-subheader"},[_vm._v("Dein Preis")]),_c('div',{staticClass:"prize-header"},[_vm._v(_vm._s(_vm.selectedPrize ? _vm.selectedPrize.name : '11 Tage Fitness'))]),_c('div',{staticClass:"prize-seperator"}),_c('div',{staticClass:"prize-footer"},[_vm._v("Hol dir deinen Gewinn")])])])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spin-button-circle"},[_c('div',{staticClass:"spin-button"},[_vm._v("SPIN")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v(" Dein nächster Schritt "),_c('br'),_vm._v(" zu mehr Fitness! ")])
}]

export { render, staticRenderFns }